import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BASE_URL } from "../../services/apiUrl";
import { DateFormat } from "../../services/Helper";
import { STATUS } from "../../services/Status";

const fetchFromLocalStorage = () => {
  let cart = localStorage.getItem("cart");
  if (cart) {
    return JSON.parse(localStorage.getItem("cart"));
  } else {
    return [];
  }
};

const storeInLocalStorage = (data) => {
  localStorage.setItem("cart", JSON.stringify(data));
};

const initialState = {
  packages: [],
  addons: [],
  status: STATUS.LOADING,
  selectedPackage: "",
  selectedPackageName: "",
  selectedPackageGst: "",
  selectedPackageRazorpay: "",
  selectedPackageConveinentFee: "",
  selectedAddons: [],
  selectedAddonsName: [],
  selectedAddonConfee: [],
  selectedPerson: "",
  selectedPersonName: "",
  selectedslot: "",
  selecteddate: new Date().toISOString(),
  cart: fetchFromLocalStorage(),
  totalItems: 0,
  totalAmount: 0,
  alreadyincart: false,
  slotavailable: true,
  termschecked: false,
  selectedDuration: "Day",
  selectslotbatch: [0, 1, 2, 3, 4, 5, 6],
};

const cartSlice = createSlice({
  name: "home",
  initialState,

  reducers: {
    setStatus(state, action) {
      state.status = action.payload;
    },

    setPackage(state, action) {
      state.packages = action.payload;
    },
    setAddons(state, action) {
      state.addons = action.payload;
    },

    setSelectedDuration(state, action) {
      state.selectedDuration = action.payload;
    },

    setselectedPackages(state, action) {
      state.selectedPackage = action.payload[0];
      state.selectedPackageName = action.payload[1];
      state.selectedPackageGst = action.payload[2];
      state.selectedPackageRazorpay = action.payload[3];
      state.selectedPackageConveinentFee = action.payload[4];
    },

    setSelectedAddons(state, action) {
      const checked = action.payload[0];
      const value = action.payload[1];
      const addonname = action.payload[2];
      const confee = action.payload[3];

      if (checked) {
        // Add the selected addon value
        if (!state.selectedAddons.includes(+value)) {
          state.selectedAddons = [...state.selectedAddons, +value];
        }

        // Add the selected addon name
        if (!state.selectedAddonsName.includes(addonname)) {
          state.selectedAddonsName = [...state.selectedAddonsName, addonname];
        }

        // Add the confee value
        state.selectedAddonConfee = [
          ...state.selectedAddonConfee,
          { value: +value, addonname, confee },
        ];
      } else {
        // Remove the addon value
        state.selectedAddons = state.selectedAddons.filter(
          (item) => item !== +value
        );

        // Remove the addon name
        state.selectedAddonsName = state.selectedAddonsName.filter(
          (item) => item !== addonname
        );

        // Remove the specific confee entry
        state.selectedAddonConfee = state.selectedAddonConfee.filter(
          (item) => item.value !== +value || item.addonname !== addonname
        );
      }
    },

    setselectedPerson(state, action) {
      state.selectedPerson = action.payload;
    },
    setSelectedPersonname(state, action) {
      state.selectedPersonName = action.payload;
    },
    setSelectedslot(state, action) {
      state.selectedslot = action.payload[0];

      const arrayData = action.payload[1]
        ? action.payload[1].split(",").map(Number) // Correctly map strings to numbers
        : null;

      if (arrayData) {
        let slotbatch = arrayData.map((day) => (day === 1 ? 0 : day - 1)); // Adjust day mapping
        state.selectslotbatch = slotbatch;
      }
    },

    setSelectedDate(state, action) {
      state.selecteddate = action.payload;
    },
    resetSelectedThings(state, action) {
      state.selectedPackage = "";
      state.selectedAddons = [];
      state.selectedAddonsName = [];
      state.selectedPackageName = "";
      state.selectedPerson = "";
      state.selectedslot = "";
      state.selecteddate = new Date().toISOString();
      state.termschecked = false;
      state.selectedPersonName = "";
      state.selectedAddonsInfo = [];
    },

    addToCart(state, action) {
      state.cart.push(action.payload);
      storeInLocalStorage(state.cart);
    },

    setSlotavailable(state, action) {
      state.slotavailable = action.payload;
    },

    removeCart(state, action) {
      state.cart = state.cart.filter((item) => item.id != action.payload);
      storeInLocalStorage(state.cart);
    },
    setTermchecked(state, action) {
      state.termschecked = action.payload;
    },
    reSetcart(state, action) {
      state.cart = [];
      localStorage.removeItem("cart");
    },
    resetPackages(state) {
      state.packages = [];
      state.addons = [];
    },
    setExpirePackagesState(state, action) {
      state.packages = [];
    },
  },
});

export const {
  setStatus,
  setPackage,
  setAddons,
  setselectedPackages,
  setSelectedAddons,
  setselectedPerson,
  setSelectedslot,
  setSelectedDate,
  resetSelectedThings,
  addToCart,
  setSlotavailable,
  removeCart,
  setTermchecked,
  reSetcart,
  resetPackages,
  setSelectedPersonname,
  setExpirePackagesState,
  setSelectedDuration,
} = cartSlice.actions;
export default cartSlice.reducer;

export const fetchPackages = (id) => {
  //console.log(location, option);
  return async function fetchPackagesThunk(dispatch) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const response = await fetch(`${BASE_URL}GetAllPackages/${id}`);
      const data = await response.json();
      dispatch(setPackage(data.data));
      dispatch(setStatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};

export const fetchAddons = (id) => {
  //console.log(location, option);
  return async function fetchAddonsThunk(dispatch) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const response = await fetch(`${BASE_URL}GetAllAddons/${id}`);
      const data = await response.json();
      dispatch(setAddons(data.data));
      dispatch(setStatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};
