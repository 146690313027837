import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import Rating from "@mui/material/Rating";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsModalVisible,
  setModalData,
} from "../../features/modal/modalSlice";
import axios from "axios";
import ModalBox from "../../common/modal/ModalBox";
import RatingTable from "./RatingTable";
import { setTotalLoader } from "../../features/user/userSlice";

const RatingStar = ({ id }) => {
  const dispatch = useDispatch();
  const [rating, setrating] = useState(0);
  const { status, modalvisible, modaldata } = useSelector(
    (state) => state.modal
  );

  const { singleItem } = useSelector((state) => state.sport);
  const {
    config: { ratingmsg },
  } = useSelector((state) => state.home);
  const {
    data: { data },
    loading,
    error,
  } = useFetch(`GetAcademyRating/${id}`);

  useEffect(() => {
    setrating(Math.round(data * 100) / 100);
  }, [data]);

  function createMarkup(text) {
    return { __html: text };
  }

  const HandleClick = (e) => {
    document.getElementById("mySidebar").classList.remove("open");
    document.getElementById("main").classList.remove("open");
    document.body.classList.remove("lock-scroll");
    document.getElementById("sidebaroverlay").classList.remove("sidebar");
    dispatch(setTotalLoader(true));

    axios
      .get(`GetAcademyRatingTable/${singleItem?.sport_id}`)
      .then((res) => {
        dispatch(setModalData(res.data));
        dispatch(setTotalLoader(false));
        dispatch(setIsModalVisible(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPlan = (rating) => {
    if (rating >= 4.5) return "Platinum";
    if (rating >= 4.0) return "Gold";
    if (rating >= 3.0) return "Silver";
    if (rating >= 2.0) return "Bronze";
    return "Basic";
  };
  const avgRating = rating;
  const plan = getPlan(avgRating);

  return (
    <>
      <div className="sp_rating px-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h2 className="siderbar_head">
              Sportzlete Rating{" "}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={HandleClick}
              ></i>
            </h2>

            <div
              className="rating-basis"
              style={{ cursor: "pointer" }}
              onClick={HandleClick}
            >
              {/* <h6 className="sub_txt mb-0">
                Click here for a comprehensive rating
              </h6>
              <small className="sub_txt">Rating Bases on?</small> */}
            </div>

            <div className="text-center mt-3 mb-4 d-none">
              <h1 className="rating_txt mb-0">
                {data && Math.round((data + Number.EPSILON) * 100) / 100}
                <span>/5</span>
              </h1>

              <Rating
                name="simple-controlled"
                value={rating}
                disabled
                precision={0.2}
              />
            </div>
            <div className="rating-grid mt-5 mb-3">
              {/* Conditionally render the appropriate plan */}

              {plan === "Platinum" && (
                <div className="rating platinum">
                  <div>Platinum</div>
                </div>
              )}
              {plan === "Gold" && (
                <div className="rating gold">
                  <div>Gold</div>
                </div>
              )}
              {plan === "Silver" && (
                <div className="rating silver">
                  <div>Silver</div>
                </div>
              )}
              {plan === "Bronze" && (
                <div className="rating bronze">
                  <div>Bronze</div>
                </div>
              )}
              {plan === "Basic" && (
                <div className="rating basic">
                  <div>Basic</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RatingStar;
