import React, { useState } from "react";
import Loader from "../common/loader/Loader";
import useFetch from "../hooks/useFetch";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "../components/login/Login.module.css";
import {
  CANCELORDER,
  getTimefromTimestamp,
  monthNames,
  toIndianCurrency,
} from "../services/Helper";
import { useDispatch, useSelector } from "react-redux";
import { setIsModalVisible } from "../features/modal/modalSlice";
import ModelBox from "../common/modal/ModalBox";
import axios from "axios";
import { toast } from "react-toastify";
import Accordian from "../common/commoncomponents/Accordian";
import {
  resetCancelReason,
  setcancelreason,
} from "../features/sports/sportSlice";
import { Link, useHistory } from "react-router-dom";
const Packages = () => {
  const { data, loading, error } = useFetch("buyedpackages");
  const dispatch = useDispatch();
  const { modalvisible } = useSelector((state) => state.modal);

  const { cancelreason } = useSelector((state) => state.sport);
  const [deleteloading, setdeleteloading] = useState(false);
  const [cancelorderid, setcancelorderid] = useState("");
  const [cancelordererror, setcancelordererror] = useState({});
  const [cancelselectionerror, setcancelselectionerror] = useState(false);

  const CancelButtoncheck = (timestamp) => {
    const createdTime = new Date(timestamp);
    const currentTime = new Date();
    const timeDiff = currentTime.getTime() - createdTime.getTime();
    //const timeDiffInHours = timeDiff / (1000 * 60 * 60);
    const timeDiffInMinutes = timeDiff / (1000 * 60);
    //timeDiffInHours <= 6
    if (timeDiffInMinutes <= 30) {
      // show button
      return true;
    } else {
      // hide button
      return false;
    }
  };

  const getHr = (timestamp) => {
    const createdTime = new Date(timestamp);
    const currentDate = new Date(timestamp);

    // Step 1: Add 6 hours
    //createdTime.setHours(createdTime.getHours() + 6);
    createdTime.setMinutes(createdTime.getMinutes() + 30);
    // Step 2: Extract hours and minutes
    const updatedHours = createdTime.getHours();
    const updatedMinutes = createdTime.getMinutes();

    // Step 3: Format the time in HH:MM AM/PM
    const formattedTime = formatTime(updatedHours, updatedMinutes);
    const formattedDate = formatDate(currentDate);
    return `${formattedDate} ${formattedTime}`;
  };

  function formatDate(currentDate) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based (0 = January)
    const day = currentDate.getDate();

    const newdate = `${day.toString().padStart(2, "0")}-${
      monthNames[currentDate.getMonth()]
    }-${year}`;

    return newdate;
  }

  function formatTime(hours, minutes) {
    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour format to 12-hour format
    const formattedHours = hours % 12 || 12;

    // Add leading zeros for single-digit minutes
    const formattedMinutes = String(minutes).padStart(2, "0");

    // Concatenate and return the formatted time
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  const Confirmcancel = (id) => {
    dispatch(setIsModalVisible(true));
    setcancelorderid(id);
  };

  const cancelorder = (e) => {
    e.preventDefault();

    if (cancelreason && cancelreason.length === 0) {
      setcancelselectionerror(true);

      setTimeout(() => {
        setcancelselectionerror(false);
      }, 1000);
      return;
    }
    //return;
    setdeleteloading(true);
    //toast.success("Successfully Cancel Your Order");

    axios
      .post(`ordercancel/${cancelorderid}`, { cancelreason })
      .then((res) => {
        setdeleteloading(false);
        dispatch(setIsModalVisible(false));
        dispatch(resetCancelReason());
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.data.error) {
          setcancelordererror({
            id: cancelorderid,
            message: "Whoops !! Something Went Wrong Please Try Again Later",
          });
        }
        console.log(error);
        dispatch(resetCancelReason());

        setTimeout(() => {
          setcancelordererror({});
        }, 5000);

        setdeleteloading(false);
        dispatch(setIsModalVisible(false));
      });
  };

  const closepopup = () => {
    dispatch(setIsModalVisible(false));
    setcancelorderid("");
    dispatch(resetCancelReason());
  };

  const DisplayAddons = (academy, selectedaddons) => {
    if (academy && academy?.addons?.length > 0) {
      const parts = selectedaddons.split(","); // Split the string using the space character as the separator

      const integers = parts.map((part) => parseInt(part));
      const filteredData = academy?.addons?.filter((item) =>
        integers.includes(item.id)
      );

      let addons = filteredData.map((data) => <span>{data?.addonname}</span>);
      return (
        <>
          <span className="strong_cls">Selected Addons</span>
          {addons}
        </>
      );
    } else {
      return;
    }
  };

  const handleCheckboxChange = (e) => {
    dispatch(setcancelreason([e.target.checked, e.target.value]));
  };

  if (loading) return <Loader />;
  return (
    <>
      {modalvisible && (
        <ModelBox>
          <div className="delete__confirmation ">
            {/* <i
              className="fa fa-warning"
              style={{ fontSize: "48px", color: "red" }}
            ></i> */}
            <h6 className="text-danger">
              Please Select the Reason For Cancel This Order
            </h6>
            <div className="cancel__information">
              {Object.keys(CANCELORDER).map((key) => (
                <>
                  <div
                    className="input-group input-group-sm"
                    style={{ gap: "10px" }}
                    key={key}
                  >
                    <input
                      type="checkbox"
                      id={`cancel${key}`}
                      value={key}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="checkbox-inline"
                      aria-describedby={`cancel${key}`}
                      id="lbProcessingConsultant"
                      htmlFor={`cancel${key}`}
                    >
                      {CANCELORDER[key]}
                    </label>
                  </div>
                </>
              ))}
              {cancelselectionerror && (
                <p className="text-danger mt-2">
                  Please Select Any Reason For Cancellation
                </p>
              )}
            </div>
          </div>
          <p className="text-center">
            Do you really want to Cancel This order ?
          </p>
          <div className="cart-options">
            {!deleteloading && (
              <button className="btn btn-primary" onClick={closepopup}>
                No
              </button>
            )}

            {deleteloading ? (
              <CircularProgress className={classes.progressbar} />
            ) : (
              <button className="btn btn-danger" onClick={cancelorder}>
                Yes Cancel Order
              </button>
            )}
          </div>
        </ModelBox>
      )}
      <div className="container">
        <div className="banner_spacer"></div>
        <div className="row login_form">
          <div className="col-lg-12 col-md-12">
            <h3 className="font_call font-weight-light text-center">
              Order History
            </h3>
          </div>
        </div>

        <div className="row" style={{ rowGap: "15px" }}>
          {data && data.length > 0 ? (
            <>
              {data.map((item, i) => (
                <>
                  <Accordian
                    title={`${item?.order_number} `}
                    key={item?.order_number}
                  >
                    {item?.orderitems.map((oitem, i) => (
                      <>
                        <div className="row" key={i}>
                          <div className="col-xs-12 col-md-6 col-lg-3">
                            <div className="form-card step1">
                              <div
                                className="pack_slider"
                                style={{ width: "250px" }}
                              >
                                <div className="pack_box mt-3">
                                  <div className="pack_box_top text-white text-center">
                                    <h5>{oitem?.packagename} </h5>
                                    <h4>₹ {oitem?.package_total_price}</h4>
                                  </div>
                                  <div className="pack_box_bottom p-3 text-left">
                                    <div
                                      className="pack_des"
                                      style={{ height: "135px" }}
                                    >
                                      <h5>
                                        <i className="fa fa-check"></i>
                                        {oitem?.comment1}
                                      </h5>
                                      <h5>
                                        <i className="fa fa-check"></i>
                                        {oitem?.comment2}
                                      </h5>
                                      <h5>
                                        <i className="fa fa-check"></i>
                                        {oitem?.comment3}
                                      </h5>
                                      <h5>
                                        <i className="fa fa-check"></i>
                                        {oitem?.comment4}
                                      </h5>
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6 col-lg-4">
                            <div
                              className="information__package d-flex"
                              style={{ flexDirection: "column", gap: "20px" }}
                            >
                              <span>Academy Name : {oitem?.academyname}</span>
                              <span>
                                Family Member Name : {oitem?.fmembername}
                              </span>
                              <span>Start Date : {oitem?.start_date}</span>

                              {DisplayAddons(oitem?.academy, oitem?.addons)}

                              <span>
                                Total Price with GST:{" "}
                                {toIndianCurrency(Number(oitem?.total_price))}
                              </span>

                              <span>
                                {" "}
                                {oitem?.status === -2 ? (
                                  <>
                                    <p className="text-danger">
                                      Order Cancelled
                                    </p>
                                  </>
                                ) : oitem.durationmonths == "Day" ? (
                                  <>
                                    <p className="text-primary">Day Pass</p>
                                  </>
                                ) : (
                                  <Link
                                    className="btn btn-primary"
                                    to={`/attendance/${oitem.id}`}
                                  >
                                    Attendance
                                  </Link>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                    {item?.status != -2 && (
                      <>
                        <div className="row">
                          <div className="col-xs-12 col-md-12 text-center">
                            <>
                              {CancelButtoncheck(item?.created_at) && (
                                <>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => Confirmcancel(item?.id)}
                                  >
                                    Cancel Order
                                  </button>
                                  <p className="text-danger mt-2">
                                    You will be able to cancel this order until{" "}
                                    {getHr(item?.created_at)}
                                  </p>
                                </>
                              )}

                              {cancelordererror &&
                                cancelordererror?.id === item?.id && (
                                  <p className="text-danger">
                                    {cancelordererror?.message}
                                  </p>
                                )}
                            </>
                          </div>
                        </div>
                      </>
                    )}
                  </Accordian>
                </>
              ))}
            </>
          ) : (
            <p className="text-center mt-5">
              You haven't purchased any package or subscriptions yet
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Packages;
