import React, { useState, useEffect } from "react";
import axios from "axios";
import { IMAGE_URL } from "../services/apiUrl";
import Mainlogo from "../assets/images/logosp.png";
import { useDispatch, useSelector } from "react-redux";
import profileimage from "../assets/images/person.png";
import { setLoggedin, setTotalLoader } from "../features/user/userSlice";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "../components/login/Login.module.css";
import CartCard from "../components/categorysingle/CartCard";
import { toIndianCurrency } from "../services/Helper";
import { useHistory } from "react-router-dom";
import { reSetcart } from "../features/cart/cartSlice";
import { toast } from "react-toastify";
import SimpleAccordion from "../common/commoncomponents/SimpleAccordion";
import Accordian from "../common/commoncomponents/Accordian";
import Table from "react-bootstrap/Table";
import CartCheckout from "../components/categorysingle/CartCheckout";
import { setIsModalVisible } from "../features/modal/modalSlice";
import ModelBox from "../common/modal/ModalBox";
const Checkout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, familymembers, status } = useSelector((state) => state.user);
  const {
    config: { place },
  } = useSelector((state) => state.home);
  const { cart } = useSelector((state) => state.cart);
  const { modalvisible } = useSelector((state) => state.modal);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState([]);
  const [expand, setexpand] = useState(false);
  const [pincodeerror, setpincodeerror] = useState(false);
  const [profileImage, setprofileImage] = useState({
    image: "",
    imagefile: "",
  });
  const [profileInfo, setprofileInfo] = useState({});

  useEffect(() => {
    if (cart && cart.length >= 2) dispatch(setIsModalVisible(true));
  }, [cart]);

  useEffect(() => {
    setprofileInfo({
      ...profileInfo,
      id: user.id,
      membername: user.membername,
      emailid: user.emailid,
      phoneno: user.phoneno,

      address: user.address === "null" ? "" : user.address,
      city: user.city === "null" ? "" : user.city,
      district: user.district ? user.district : "",
      pincode: user.pincode === "null" ? "" : user.pincode,
      displayimg: user.displayimg || profileimage,
    });

    setprofileImage({
      ...profileImage,
      image: user.displayimg,
    });
  }, [user]);
  const Handleinput = (e) => {
    e.preventDefault();
    setprofileInfo({ ...profileInfo, [e.target.name]: e.target.value });
    if (e.target.name === "pincode" && e.target.value.length > 3)
      getStatewithpincode(e.target.value);
  };

  const getStatewithpincode = async (value) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${value}`
      );
      const jsonData = await response.json();
      //console.log(jsonData[0]?.PostOffice[0]);

      if (jsonData[0]) {
        if (jsonData[0]?.Status === "404" || jsonData[0]?.Status === "Error") {
          setpincodeerror(true);
          setprofileInfo({
            ...profileInfo,
            ["district"]: "",
            ["pincode"]: value,
          });
        } else {
          if (jsonData[0]?.PostOffice[0]?.State)
            setprofileInfo({
              ...profileInfo,
              ["district"]: jsonData[0]?.PostOffice[0]?.State,
              ["pincode"]: value,
            });
          setpincodeerror(false);
        }
      } else {
        setprofileInfo({
          ...profileInfo,
          ["district"]: "",
          ["pincode"]: value,
        });
        setpincodeerror(true);
      }
    } catch (error) {
      setprofileInfo({
        ...profileInfo,
        ["district"]: "",
      });
      setpincodeerror(true);
    }
  };

  const Findimage = (string) => {
    let image;
    if (string === null) {
      image = profileimage;
    } else if (string.includes("base64")) {
      image = string;
    } else if (string.includes("static")) {
      image = string;
    } else if (string.includes("https")) {
      image = string;
    } else {
      image = `${IMAGE_URL}${string}`;
    }

    return image;
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(credentials) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // const result = await axios.post("http://localhost:5000/payment/orders");

    // if (!result) {
    //   alert("Server error. Are you online?");
    //   return;
    // }

    const { amount, order_id, currency } = credentials;

    const name = user?.membername;
    const email = user?.email;
    const phone = user?.phoneno;

    const options = {
      key: "rzp_live_3xY3iAEMoGgh6n", // Enter the Key ID generated from the Dashboard rzp_test_2l4n4KlV7axxd7
      //rzp_live_3xY3iAEMoGgh6n
      amount: amount.toString(),
      currency: currency,
      name: { name },
      description: "Sportzlete",
      image: { Mainlogo },
      order_id: order_id,
      handler: async function (response) {
        //console.log(response, "from payment");
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        //console.log(data, "from");

        const result = await axios.post("verifypay", data);

        if (result.data.verify) {
          dispatch(setTotalLoader(true));
          const order_data = {
            cart: cart,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            total_amount: cart.reduce(
              (total, item) => item.totalpriceGst + total,
              0
            ),
          };

          try {
            const order = await axios.post("ordercomplete", order_data);

            if (order) {
              dispatch(setTotalLoader(false));
              dispatch(reSetcart());
              history.push("/packages");
              toast("Successfully Completed Your Payment");
              // localStorage.removeItem("cart");
            } else {
              dispatch(setTotalLoader(false));
              toast.error("Whoops !! Something Went Wrong");
              return;
            }
          } catch (error) {
            dispatch(setTotalLoader(false));
            toast.error(
              "An error occurred. Please try again later.If Payment Debited It Will Refund Shortly"
            );
            return;
          }

          // alert(result.data.message);
        } else {
          toast.error("Whoops !! Something Went Wrong");
          return;
        }
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        address: place || "Bengaluru,Karnataka",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", function (response) {
      toast.error(response.error.description);
      return;
    });
    paymentObject.open();
  }

  const CheckoutSubmit = (e) => {
    e.preventDefault();

    const total = cart.reduce((total, item) => total + item.totalpriceGst, 0);

    setloading(true);
    axios
      .post("createorder", {
        amount: total,
        address: profileInfo.address,
        city: profileInfo.city,
        pincode: profileInfo.pincode,
      })
      .then((res) => {
        displayRazorpay(res.data);
        setloading(false);
        setexpand(false);
      })
      .catch((error) => {
        setloading(false);
        setexpand(true);
        if (error.response.data.errors) seterror(error.response.data.errors);
      });
  };
  return (
    <>
      {modalvisible && (
        <>
          <ModelBox>
            <p style={{ textAlign: "justify" }}>
              We have noticed that you've ordered more than one item in your
              current order, and we would like to provide you with some
              important information regarding cancellation. Due to our
              streamlined order processing system, if you decide to cancel any
              item within your order, please be aware that the entire order will
              be cancelled, including all items within it. This is because we
              process orders efficiently to ensure timely delivery, and any
              cancellations impact the entire order. We understand that
              sometimes circumstances change, and you might need to make
              adjustments to your order. In such a case, please cancel the order
              and place the order again with only required items in it.
            </p>
          </ModelBox>
        </>
      )}
      <div className="container">
        <div className="banner_spacer"></div>
        <div className="row login_form">
          <div className="col-lg-12 col-md-12">
            <h3 className="font_call font-weight-light text-center">My Cart</h3>
          </div>
          <div className="banner_spacer"></div>

          {/* <div class="row align-items-center py-2">
            <div className="col-sm-7 col-7  strong_cls">
              Package and Price Details
            </div>
            <div className="col-sm-4 col-4 p-0 text-center strong_cls">
              Total
            </div>
            <div className="col-1 px-0 strong_cls">Action</div>
          </div> */}

          <div className="col-xs-12 col-md-12">
            {cart && cart.length > 0 ? (
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Package Details</th>
                      <th className="align_right">Item Cost</th>
                      <th className="align_right">GST</th>
                      <th className="align_right">Conveinence</th>
                      <th className="align_right">Total</th>
                      <th className="align_center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map((cartItem) => (
                      <CartCheckout
                        key={cartItem?.id}
                        cart={cartItem}
                        type="checkout"
                      />
                    ))}
                    {cart.length > 0 && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="align_right">
                          <strong>
                            Sub Total :{" "}
                            {toIndianCurrency(
                              cart.reduce(
                                (total, item) => total + item.totalpriceGst,
                                0
                              )
                            )}
                          </strong>
                        </td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            ) : (
              <p className="text-center">
                Your cart is currently empty , Kindly Add Any Packages To
                Continue Checkout
              </p>
            )}
          </div>

          <Accordian title="Billing Information" expandtype={expand}>
            <div className="col-xs-12 col-md-12 mt-3">
              <form className="registration_form">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    {/* <div className="row align-items-center mt-3 row_flex">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <p className="log_label mt-0">Display Image</p>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <img
                          src={Findimage(profileImage.image || profileimage)}
                          alt="Profile"
                          className="profilepreview img-fluid"
                        />
                      </div>
                    </div> */}
                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="mem_name" className="mt-0 log_label">
                          Member Name
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <p>{profileInfo.membername}</p>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="mem_email" className="mt-0 log_label">
                          Email Id
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <p>{profileInfo.emailid}</p>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="mem_phnum" className="mt-0 log_label">
                          Phone Number
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <p>{profileInfo.phoneno}</p>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="mem_address" className="mt-0 log_label">
                          Address
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="text"
                          name="address"
                          id="mem_address"
                          onChange={Handleinput}
                          value={profileInfo.address || ""}
                          placeholder="Enter Address"
                          className="form-control profile_ip"
                        />
                        {error.length !== 0 && error.address ? (
                          <p className={classes.validation}>
                            {error.address[0]}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="mem_city" className="mt-0 log_label">
                          City
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="text"
                          name="city"
                          onChange={Handleinput}
                          value={profileInfo.city || ""}
                          id="mem_city"
                          placeholder="Enter City"
                          className="form-control profile_ip"
                        />
                        {error.length !== 0 && error.city ? (
                          <p className={classes.validation}>{error.city[0]}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="mem_pincode" className="mt-0 log_label">
                          Pincode
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="text"
                          name="pincode"
                          value={profileInfo.pincode || ""}
                          id="mem_pincode"
                          onChange={Handleinput}
                          placeholder="Enter Pincode"
                          className="form-control profile_ip"
                        />
                        {error.length !== 0 && error.pincode ? (
                          <p className={classes.validation}>
                            {error.pincode[0]}
                          </p>
                        ) : null}
                        {pincodeerror && (
                          <small className="text-danger">Invalid Pin</small>
                        )}
                      </div>
                    </div>
                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="mem_city" className="mt-0 log_label">
                          State
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="text"
                          name="district"
                          onChange={Handleinput}
                          value={profileInfo.district}
                          id="mem_district"
                          placeholder=""
                          readOnly
                          className="form-control profile_ip"
                        />
                        {error.length !== 0 && error.district ? (
                          <p className={classes.validation}>
                            {error.district[0]}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="banner_spacer"></div>
              <div className="banner_spacer"></div>
            </div>
          </Accordian>
          {cart.length > 0 && (
            <div className="cart__total text-center">
              {/* <strong>
                Sub Total :{" "}
                {toIndianCurrency(
                  cart.reduce((total, item) => total + item.totalpriceGst, 0)
                )}
              </strong> */}

              {loading ? (
                <div className="button-checkout text-center mt-3">
                  <CircularProgress className={classes.progressbar} />
                </div>
              ) : (
                <button
                  className="checkout-button mb-4"
                  onClick={CheckoutSubmit}
                >
                  Checkout{" "}
                  {toIndianCurrency(
                    cart.reduce((total, item) => total + item.totalpriceGst, 0)
                  )}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Checkout;
